import type { PostExcerpt } from 'types/posts';
import type { PageExcerpt } from 'types/page';
import type { CardProps } from './card';
import React from 'react';
import { Paragraph } from '@leafwell/components';
import Card from './card';
import ArticleJson from './structured-data/article';
import HeadingBody from './heading-body';
import LinkArrow from './link-arrow';
import { useWordPressContext } from 'contexts/wordpress-provider';

export type CardsGridProps = {
  displayBlogButton?: boolean;
  items: (PageExcerpt | PostExcerpt)[];
  title?: string;
  subtitle?: string;
  variant?: 'small' | 'smallFeatured' | 'medium' | 'featured' | 'landing';
};

const CardsGrid: React.FC<CardsGridProps> = ({
  displayBlogButton = false,
  items,
  title,
  subtitle,
  variant = 'small',
}) => {
  const { translations, blogPage } = useWordPressContext();
  const getCardProps = React.useCallback(
    key => {
      const isFirst = key === 0;
      const isLast = key === items.length - 1;
      const defaultClassNames = 'col-span-full sm:col-span-6';

      switch (variant) {
        case 'featured':
          return {
            className: isFirst
              ? `${defaultClassNames} xl:col-span-7 xl:row-span-3 content-start`
              : `${defaultClassNames} xl:col-span-5 xl:grid-cols-2 xl:grid-flow-col items-start`,
            showAuthor: isFirst,
            showDescription: false,
            variant: (isFirst ? 'large' : 'medium') as CardProps['variant'],
          };
        case 'small':
          return {
            className: [
              `${defaultClassNames} lg:col-span-3`,
              isFirst ? '' : 'lg:border-l lg:border-indigo-200 lg:pl-4',
              isLast ? '' : 'lg:pr-4',
            ].join(' '),
            showCategories: false,
            showDate: true,
            showDescription: false,
            showImage: false,
            variant: 'small' as const,
          };
        case 'landing':
          return {
            className: [` grid col-span-full`].join(' '),
            showCategories: false,
            showDate: true,
            showDescription: false,
            showImage: true,
            variant: 'small' as const,
          };
        case 'smallFeatured':
          return {
            className: isFirst
              ? `${defaultClassNames} md:col-span-6 content-start`
              : `${defaultClassNames} md:col-span-3 content-start`,
          };
        default:
          return {
            className: `${defaultClassNames} content-start`,
            variant: 'large' as const,
          };
      }
    },
    [items, variant],
  );

  return (
    <section
      className={[
        'grid sm:grid-cols-12 gap-y-10 md:gap-y-8',
        variant === 'featured' ? 'content-start' : '',
        variant === 'small' ? 'gap-x-4' : 'gap-x-10',
      ].join(' ')}
    >
      <span className={displayBlogButton ? 'sm:col-span-6' : 'col-span-full'}>
        {title?.length > 0 && (
          <HeadingBody
            level="3"
            title={title}
            className="!text-lg !font-sans my-reset prose"
          />
        )}
        {subtitle?.length > 0 && (
          <Paragraph className="" size="small" variant="medium">
            {subtitle}
          </Paragraph>
        )}
      </span>
      {displayBlogButton && (
        <LinkArrow
          className="order-last sm:order-none sm:col-span-6 justify-self-start sm:justify-self-end sm:mt-0"
          title={translations['See more posts']}
          showLine={false}
          uri={blogPage?.uri ? blogPage.uri : '/blog'}
        />
      )}
      {items.map((item, key) => (
        <React.Fragment key={item.id}>
          <ArticleJson {...item} />
          <Card
            isFeatured={variant === 'featured'}
            link={item.uri}
            {...item}
            {...getCardProps(key)}
          />
        </React.Fragment>
      ))}
    </section>
  );
};

export default CardsGrid;
