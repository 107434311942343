import type { Author, Media } from 'types';
import React from 'react';
import Image from 'next/image';
import {
  Avatar,
  Paragraph,
  Card,
  Wrap,
  Logo,
  Badge,
} from '@leafwell/components';
import Figure from './figure';
import HeadingBody from './heading-body';
import SocialItems from './social-items';
import { useEventsTracker } from 'contexts/eventsTracker';

const AuthorProfilePic: React.FC<{
  image: Media;
  className?: string;
}> = ({ image, className }) => {
  return (
    <div
      className={[
        'relative flex justify-center items-center	w-68 h-64',
        className,
      ].join(' ')}
      style={{
        backgroundImage: `url('/author-pic-border.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Figure {...image} className="!absolute rounded-full	w-44 h-44" />
    </div>
  );
};

const AuthorProfile: React.FC<
  Author & {
    className?: string;
  }
> = ({ image, jobTitles, quote, socialNetworks, name, className }) => {
  return (
    <Wrap size="large" className={['pb-reset !gap-y-4', className].join(' ')}>
      <div className="group flex gap-x-2">
        <HeadingBody level="1" title={name} tagName="h1" />
        <Logo variant="icon" />
      </div>
      <div className="flex flex-col gap-y-4 md:flex-row md:justify-between">
        <div className="flex gap-x-4">
          {jobTitles?.length > 0 &&
            jobTitles.map((jobTitle, idx) => {
              return (
                <Badge
                  key={`jobTitle${idx}`}
                  className="!font-normal border border-black"
                  label={jobTitle}
                  variant="white"
                />
              );
            })}
        </div>
        {socialNetworks?.length > 0 && (
          <SocialItems customItems={socialNetworks} />
        )}
      </div>
      <Card
        filled={true}
        rounded={true}
        className="grid justify-items-center xl:grid-cols-2-auto xl:justify-items-start lg:gap-x-48 lg:py-20 lg:px-32"
      >
        <AuthorProfilePic image={image} className="mt-2" />
        <div>
          <HeadingBody
            level="2"
            title={`<img src='/asterisk-orange.svg' width='40' height='40' className="inline-block"/> ${
              quote ? quote : ''
            }`}
            tagName="p"
            className="lg:!leading-snug"
          />
          <Paragraph className="mt-8 font-bold">{name}</Paragraph>
          {jobTitles?.length > 0 && (
            <Paragraph className="">
              {jobTitles.map(jobTitle => jobTitle).join(', ')}
            </Paragraph>
          )}
        </div>
      </Card>
    </Wrap>
  );
};

const AuthorPreview: React.FC<
  Author & {
    className?: string;
    variant?: 'small' | 'large' | 'xlarge';
    hasBottomMargin?: boolean;
  }
> = ({
  image,
  jobTitles,
  className = '',
  description,
  name,
  variant = 'small',
  uri,
  hasBottomMargin,
}) => {
  const isLarge = variant !== 'small';
  const { trackEvent } = useEventsTracker();

  return (
    <a
      className={[
        'grid gap-y-3',
        variant === 'xlarge' ? 'md:items-center ' : 'md:items-start',
        isLarge ? 'md:grid-cols-12' : 'md:grid-cols-2-auto',
        hasBottomMargin ? 'mb-8' : '',
        className,
      ].join(' ')}
      href={uri}
      onClick={() =>
        trackEvent({
          event: 'select_content',
          content_type: name,
          item_id: 'author',
          origin: isLarge ? 'blog_post' : 'blog',
        })
      }
    >
      <div
        className={[
          'grid grid-cols-2-auto items-center',
          isLarge ? 'gap-x-3 md:col-span-3' : 'gap-x-2',
        ].join(' ')}
      >
        <Avatar
          size={isLarge ? variant : 'medium'}
          image={image?.src}
          width="32"
          height="32"
          Component={Image}
        />
        <Paragraph size="small" className="grid" tagName="p">
          {name}
          {jobTitles?.length > 0 && (
            <Paragraph size="xsmall" variant="medium" tagName="span">
              {jobTitles.map(jobTitle => jobTitle).join(', ')}
            </Paragraph>
          )}
        </Paragraph>
      </div>
      {isLarge && (
        <Paragraph
          size="small"
          variant="medium"
          className="md:col-span-9"
          dangerouslySetInnerHTML={{ __html: description }}
        ></Paragraph>
      )}
    </a>
  );
};

const AuthorCard: React.FC<
  Author & {
    className?: string;
    isPageTitle?: boolean;
    variant?: 'small' | 'large' | 'xlarge';
    hasBottomMargin?: boolean;
  }
> = ({ isPageTitle = false, ...authorCardProps }) => {
  return isPageTitle ? (
    <AuthorProfile {...authorCardProps} />
  ) : (
    <AuthorPreview {...authorCardProps} />
  );
};

export default AuthorCard;
