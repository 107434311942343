import { useRouter } from 'next/router';

export function useDateFormat(
  date?: string | number,
  longDate?: boolean,
): string | null {
  const { locale } = useRouter();

  if (!date) {
    return null;
  }

  return new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: longDate ? 'long' : 'short',
    day: 'numeric',
  });
}
