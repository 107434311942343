import type { PostExcerpt, PageExcerpt, CategoryPost } from 'types';
import type { LinkProps } from '@leafwell/components';
import React, { useId } from 'react';
import { Link as LinkComponent, Paragraph } from '@leafwell/components';
import Image from 'next/image';
import AuthorInfo from './author-info';
import HeadingBody from './heading-body';
import Figure from './figure';
import { useDateFormat } from 'hooks/useDateFormat';
import { useEventsTracker } from 'contexts/eventsTracker';

export type CardProps = (
  | PageExcerpt
  | Omit<PostExcerpt, 'categories' | 'postAuthors'>
) & {
  postAuthor?: PostExcerpt['postAuthor'];
  categories?: PostExcerpt['categories'];
  className?: string;
  showAuthor?: boolean;
  showCategories?: boolean;
  showDate?: boolean;
  showDescription?: boolean;
  showImage?: boolean;
  isFeatured?: boolean;
  variant?: 'small' | 'medium' | 'large';
  link?: string;
};

const CardLink: React.FC<LinkProps> = props => <LinkComponent {...props} />;

export const CardCategories: React.FC<{
  categories?: CategoryPost[];
}> = ({ categories }) => {
  return categories ? (
    <ul className="flex flex-wrap">
      {categories.map(({ id, name, uri }) => (
        <li key={id} className="pr-3">
          <CardLink href={uri} className="uppercase text-yellow-500 text-xs">
            {name}
          </CardLink>
        </li>
      ))}
    </ul>
  ) : null;
};

const Card: React.FC<CardProps> = ({
  categories = null,
  date,
  className = '',
  title,
  featuredMedia,
  postAuthor,
  seo,
  showAuthor = false,
  showCategories = true,
  showDate = false,
  showDescription = true,
  showImage = true,
  isFeatured = false,
  variant = 'medium',
  link,
}) => {
  const imageId = useId();
  const isSmall = variant === 'small';
  const authors = React.useMemo(() => {
    if (showAuthor && postAuthor?.length) {
      return postAuthor;
    }

    return null;
  }, [postAuthor, showAuthor]);
  const image = React.useMemo(() => {
    if (featuredMedia) {
      return featuredMedia;
    }

    return {
      id: imageId,
      alt: '',
      src: '/share.jpg',
      srcSet: null,
    };
  }, [featuredMedia]);
  const postDate = useDateFormat(showDate ? date : null);
  const postDescription = React.useMemo(() => {
    if (variant !== 'small' && seo && showDescription) {
      return seo.description;
    }

    return null;
  }, [seo, showDescription, variant]);
  const headingLevel = React.useMemo(() => {
    switch (variant) {
      case 'large':
        return '4';
      case 'small':
        return '6';
      default:
        return '5';
    }
  }, [variant]);
  const { trackEvent } = useEventsTracker();

  return (
    <article
      className={['grid gap-x-6 lg:gap-x-10 gap-y-3', className].join(' ')}
    >
      {showImage && (
        <CardLink
          href={link}
          onClick={() => {
            trackEvent({
              event: 'select_content',
              content_type: title,
              item_id: 'blog_post',
              origin: 'blog_posts_list',
            });
          }}
        >
          <Figure
            className={[
              'aspect-w-16',
              variant === 'large' ? 'aspect-h-8' : 'aspect-h-9',
            ].join(' ')}
            classNameImage="transform transition-transform duration-500 ease-out hover:scale-110"
            objectFit="cover"
            priority={isFeatured}
            {...image}
          />
        </CardLink>
      )}
      <div
        className={[
          'grid',
          isSmall ? 'grid-rows-card-small grid-cols-2 gap-y-6' : 'gap-y-0',
        ].join(' ')}
      >
        {showCategories && <CardCategories categories={categories} />}
        <CardLink
          href={link}
          variant="underlineHover"
          className="col-span-full"
          onClick={() => {
            trackEvent({
              event: 'select_content',
              content_type: title,
              item_id: 'blog_post',
              origin: 'blog_posts_list',
            });
          }}
        >
          <HeadingBody level={headingLevel} title={title} />
        </CardLink>
        {authors &&
          authors.length &&
          authors.map(author => (
            <AuthorInfo
              {...author}
              variant="small"
              key={author.id}
              className="col-span-full pt-3"
            />
          ))}
        {postDescription && (
          <Paragraph
            variant="medium"
            size={variant === 'large' ? 'medium' : 'small'}
            dangerouslySetInnerHTML={{ __html: postDescription }}
            className="col-span-full pt-2"
          />
        )}
        {postDate && (
          <Paragraph
            size="xsmall"
            variant="medium"
            tagName="time"
            className="grid grid-flow-col justify-between"
          >
            {postDate}
          </Paragraph>
        )}
        {isSmall && (
          <CardLink
            href={link}
            className="justify-self-end"
            aria-label={`Link to ${title}`}
          >
            <Image
              width={15}
              height={12}
              src="/arrow.svg"
              alt=""
              className="justify-self-end transition-opacity hover:opacity-50"
            />
          </CardLink>
        )}
      </div>
    </article>
  );
};

export default Card;
