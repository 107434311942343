import type { LinkProps } from '@leafwell/components';

import React, { memo } from 'react';
import { Link as LinkComponent } from '@leafwell/components';
import Image from 'next/image';

import Line from './line';

const LinkArrow: React.FC<
  Omit<LinkProps, 'href' | 'variant'> & {
    showLine?: boolean;
    showTitle?: boolean;
    title: string;
    uri: string;
    variant?: LinkProps['variant'] | 'highlight';
    classNameImg?: string;
  }
> = ({
  className = '',
  classNameImg = '',
  showLine = true,
  showTitle = true,
  title,
  uri,
  variant = 'default',
  ...props
}) => {
  const isHighlight = variant === 'highlight';

  return (
    <>
      <LinkComponent
        {...{
          ...props,
          className: [
            'group flex gap-x-3 justify-items-between items-center',
            isHighlight
              ? 'text-base before:-z-1 before:absolute before:rounded-md before:-inset-x-3 before:-inset-y-2 hover:before:bg-lilac-50'
              : 'text-sm mt-2',
            className,
          ].join(' '),
          href: uri,
          variant: isHighlight ? 'underlineHover' : variant,
        }}
      >
        <span
          className={['grow text-lg', showTitle ? '' : 'sr-only'].join(' ')}
        >
          {title}
        </span>
        <Image
          width={15}
          height={12}
          src="/arrow.svg"
          alt=""
          className={[
            'justify-self-end transition-opacity group-hover:opacity-50',
            classNameImg,
          ].join(' ')}
        />
      </LinkComponent>
      {showLine && <Line className="mt-3" />}
    </>
  );
};

export default memo(LinkArrow);
