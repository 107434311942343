import type { WrapProps } from '@leafwell/components';
import React from 'react';
import { Wrap } from '@leafwell/components';

const Line: React.FC<{
  className?: string;
  containerSize?: string | WrapProps['size'];
  color?: string;
}> = ({ className = '', containerSize, color = 'gray' }) => {
  const Wrappper = containerSize ? Wrap : React.Fragment;

  return (
    <Wrappper {...(containerSize ? { size: containerSize } : {})}>
      <hr
        className={[
          'col-span-full border-t',
          color === 'gray' ? 'border-[#DEE0DE]' : 'border-black',
          className,
        ].join(' ')}
      />
    </Wrappper>
  );
};

export default Line;
